import Grid from "@material-ui/core/Grid"
import ReactAudioPlayer from "react-audio-player"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialShare from "../components/socialShare"
import {
  Blog,
  Wrapper,
  Title,
  TitleSocialWrapper,
} from "../components/styles/styles"
import Snackbar from "@material-ui/core/Snackbar"
import SuggestionsBox from "../components/suggestion_v2"
import { formatDate } from "../utils/utils"
import { Link, graphql } from "gatsby"
import ContentSection_md from "../components/homepage/ContentSection-md"
import words from "lodash/words"

const Tags = ({ pageContext, data }) => {
  console.log("pageContext", pageContext)
  const { tag } = pageContext
  console.log("Tags", tag)
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${words(tag.split("/"))}"`
  return (
    <div>
      <Layout>
        <hr style={{ background: "white" }} />
        <ContentSection_md
          title={tagHeader}
          // text={tagHeader}
          data={data.allMarkdownRemark}
          lg={3}
          xs={12}
          sm={4}
          height="180px"
          style={{ image: "contain", height: "180px" }}
        />
      </Layout>
      <h1>{tagHeader}</h1>
      <ul>
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { title } = node.frontmatter
          return (
            <li key={slug}>
              <Link to={slug}>{title}</Link>
            </li>
          )
        })}
      </ul>
      {/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
      <Link to="/tags">All tags</Link>
    </div>
  )
}
export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { regex: $tag } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            date
            content
            preparationTime
            serving
            category
            region
            tags
            suggested_articles
            suggested_recipes
            suggested_wines
            featuredImage {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
